import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const testimonialList = [
  {
    testimonial:
      "Jogn Due The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections Bonorum et Malorum original.",
    name: "Jogn Due",
    position: "COO, AMERIMAR ENTERPRISES, INC.",
    image: "/assets/images/client/testimonial-1.jpg",
  },
  {
    testimonial:
      "Martha Linn The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections Bonorum et Malorum original.",
    name: "Martha Linn",
    position: "CFO, PRIMER TECH, INC.",
    image: "/assets/images/client/testimonial-2.jpg",
  },
  {
    testimonial:
      "Linda Owen The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections Bonorum et Malorum original.",
    name: "Linda Owen",
    position: "CEO, THE PEOPLE'S HOTSPOT.",
    image: "/assets/images/client/testimonial-3.jpg",
  },
];

class Testimonial extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12">
            <Tabs>
              <div className="row align-items-center">
                <div className="col-lg-6">
                  {testimonialList.map((t, idx) => (
                    <TabPanel key={idx}>
                      <div className="rn-testimonial-content text-left">
                        <div className="inner">
                          <p>{t.testimonial}</p>
                        </div>
                        <div className="author-info">
                          <h6>
                            <span>{t.name} </span> - {t.position}
                          </h6>
                        </div>
                      </div>
                    </TabPanel>
                  ))}
                </div>
                <div className="col-lg-6 mt_md--40 mt_sm--40">
                  <TabList className="testimonial-thumb-wrapper">
                    {testimonialList.map((t, idx) => (
                      <Tab key={idx}>
                        <div className="testimonial-thumbnai">
                          <div className="thumb">
                            <img src={t.image} alt="Testimonial Images" />
                          </div>
                        </div>
                      </Tab>
                    ))}
                  </TabList>
                </div>
              </div>
            </Tabs>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Testimonial;
