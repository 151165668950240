import React, { Component } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
// import { Link } from "react-router-dom";

import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
// import CounterOne from "../elements/counters/CounterOne";
// import Testimonial from "../elements/Testimonial";
import About from "../component/HomeLayout/homeOne/About";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
// import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import Helmet from "../component/common/Helmet";
import AboutTwo from "../component/HomeLayout/homeOne/AboutTwo";
import ServiceOne from "../elements/service/ServiceOne";
// import HeaderFive from "../component/header/HeaderFive";
// import PortfolioGalleryPopup from "../elements/portfolio/PortfolioGalleryPopup";
// import ServiceList from "../elements/service/ServiceList";
// import Brand from "../elements/Brand";


class MainDemo extends Component {
  render() {
    // const PostList = BlogContent.slice(0, 3);
    return (
      <div className="active-dark">
        <Helmet pageTitle="Acquira Labs" />
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* <HeaderFive
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        /> */}

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <SliderOne />
        </div>
        {/* End Slider Area   */}

        {/* Start About Area */}
        <div className="about-area ptb--120 bg_color--1">
          <About />
        </div>

        <div className="about-area ptb--120 bg_color--1">
          <AboutTwo />
        </div>
        {/* End About Area */}

        {/* Start Service Area  */}
        <div className="service-area ptb--120 bg_color--5">
          <div className="container">
            <ServiceTwo />
          </div>
          <div className="container">
            <ServiceOne />
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Portfolio Area */}
        <div className="portfolio-area ptb--120 bg_color--1">
          <div className="portfolio-sacousel-inner mb--55">
            <Portfolio />
          </div>
        </div>
        {/* End Portfolio Area */}

        {/* Start CounterUp Area */}
        {/* <div className="rn-counterup-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="subtitle">Experts growts</span>
                  <h2 className="title">Our Company Growth</h2>
                  <p className="description">
                    We have grown strength over the past 20 years.
                  </p>
                </div>
              </div>
            </div>
            <CounterOne />
          </div>
        </div> */}
        {/* End CounterUp Area */}

        {/* Start Testimonial Area */}
        {/* <div className="rn-testimonial-area bg_color--1 ptb--120">
          <div className="container">
            <Testimonial />
          </div>
        </div> */}
        {/* End Testimonial Area */}

        {/* Start Blog Area */}
        {/* <div className="rn-blog-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="section-title text-center">
                  <span className="subtitle">what's stories on</span>
                  <h2 className="title">Latest News</h2>
                  <p className="description">
                    There are many variations of passages of Lorem Ipsum
                    available, <br /> but the majority have suffered alteration.
                  </p>
                </div>
              </div>
            </div>
            <div className="row mt--30 mt_sm--40">
              {PostList.map((value, i) => (
                <div className="col-lg-4 col-md-6 col-12 mt--30" key={i}>
                  <div className="im_box">
                    <div className="thumbnail">
                      <Link to="/blog-details">
                        <img
                          className="w-100"
                          src={`/assets/images/blog/blog-${value.images}.jpg`}
                          alt="Blog Images"
                        />
                      </Link>
                    </div>
                    <div className="content">
                      <div className="inner">
                        <div className="content_heading">
                          <div className="category_list">
                            <Link to="/portfolio-details">
                              {value.category}
                            </Link>
                          </div>
                          <h4 className="title">
                            <Link to="/blog-details">{value.title}</Link>
                          </h4>
                        </div>
                        <div className="content_footer">
                          <Link
                            to="/blog-details"
                            className="rn-btn btn-opacity"
                          >
                            Read More
                          </Link>
                        </div>
                      </div>
                      <Link
                        className="transparent_link"
                        to="/blog-details"
                      ></Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div> */}
        {/* End Blog Area */}

        {/* Start Brand Area */}
        <div className="rn-brand-area bg_color--1 ptb--120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="section-title text-center mb--30">
                  <span className="subtitle">Top clients</span>
                  <h2 className="title">Brands we've worked with</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <BrandTwo />
              </div>
            </div>
          </div>
        </div>
        {/* End Brand Area */}
        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </div>
    );
  }
}
export default MainDemo;
