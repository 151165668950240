import React, { Component } from "react";
import { FiMonitor, FiSmartphone, FiEdit3 } from "react-icons/fi";

const ServiceList = [
  {
    icon: <FiEdit3 />,
    title: "UI/UX Design",
    description: "Onboard and retain users of your product with beautiful, engaging and easy-to-use interfaces that enhance user experience.",
  },
  {
    icon: <FiMonitor />,
    title: "Web Development",
    description: "We build scalable web application solutions properly designed to meet your user's needs.",
  },
  {
    icon: <FiSmartphone />,
    title: "Mobile Development",
    description: "We architect mobile applications that put your solutions in the hands of millions of mobile users.",
  },
  // {
  //   icon: <FiTrendingUp />,
  //   title: "Digital Marketing",
  //   description: "We help you reach your target audience and manage the growth of your online presence to acquire more users and make sales.",
  // },
];
class ServiceOne extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="row row--25">
          {ServiceList.map((val, i) => (
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-5" key={i}>
              <div className="service service__style--1">
                <div className="icon" id="mod-svg">
                  {val.icon}
                </div>
                <div className="content">
                  <h4 className="title">{val.title}</h4>
                  <p>{val.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceOne;
