import React, { Component } from "react";

class AboutTwo extends Component {
  render() {
    // let title = "About",
    //   description =
    //     "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum,";
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--20 align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/about/glasses-see.png"
                    alt="About Images"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <span className="subtitle">How we work</span>
                    <h2 className="title">Our Work Process</h2>
                    <p>
                      We dedicate most of our time simplifying processes,
                      getting our best work out and making our clients happy.
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h5 className="title">We listen</h5>
                        <p>Tell us about your project as we pay attention.</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h5 className="title">We understand</h5>
                        <p>We gain a deep understanding of your needs.</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h5 className="title">We plan</h5>
                        <p>
                          We get together with you to lay the groundwork for a
                          great project starter.
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h5 className="title">We deliver</h5>
                        <p>
                          We jump in on our end to bring you the best possible solution.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="purchase-btn mt--50">
                                        <a className="btn-transparent" href="/about">WHY WE'RE DIFFERNT VALUES</a>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AboutTwo;
