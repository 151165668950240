import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { AQLabsContext } from "../../../contexts";

// import PortfolioGalleryPopup from "../../../elements/portfolio/PortfolioGalleryPopup";

const Portfolio_image = (
  <img
    src="/assets/images/portfolio/a-home-to-adore.jpg"
    alt="React Creative Agency"
  />
);
const Portfolio_image2 = (
  <img
    src="/assets/images/portfolio/sunshine-reveal.jpg"
    alt="React Creative Agency"
  />
);
// const Portfolio_image3 = (
//   <img
//     src="/assets/images/portfolio/portfolio-10.jpg"
//     alt="React Creative Agency"
//   />
// );

// --- CATEGORIES ---
// Web Development
// Mobile Development
// UI/UX Design

const PortfolioList = [
  {
    image: Portfolio_image,
    category: "Web Development",
    title: "A Home To Adore",
    description:
      "A household products store with elegant pieces of home items.",
    type_of_business: "Home Essentials Store",
    website: "https://ahometoadore.com",
  },
  {
    image: Portfolio_image2,
    category: "Web Development",
    title: "Sunshine Reveal",
    description:
      "A black-owned modern clothing line for the classic, funky and stylish.",
    type_of_business: "Clothing Store",
    website: "https://hellosunshinereveal.com",
  },
  // {
  //   image: Portfolio_image3,
  //   category: "Application",
  //   title: "Photoshop Design",
  //   description: "Lorem ipsum dolor sit amet, consec tetur adipiscing elit.",
  // },
];

class Portfolio extends Component {
  static contextType = AQLabsContext;

  render() {
    let title = "Works we've done",
      description =
        "Our projects range from design, development to business growth and support.";

    // const sanitize = (value) => {
    //   const replacedText = value.replaceAll(" ", "-");
    //   return replacedText;
    // };

    // const { setCurProject } = this.context;

    return (
      <React.Fragment>
        <div className="portfolio-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="subtitle">Our projects</span>
                  <h2 className="title">{title}</h2>
                  <p
                    className="description"
                    dangerouslySetInnerHTML={{ __html: description }}
                  ></p>
                </div>
              </div>
            </div>

            {/* <div className="row port-pop">
              <PortfolioGalleryPopup
                column={"col-lg-4 col-md-6 col-sm-6 col-12 mt--30"}
                // item={5}
              />
            </div> */}

            <div className="row mt--30 justify-content-center">
              {PortfolioList.map((value, index) => (
                <div
                  className="col-lg-4 col-md-6 col-sm-6 col-12 mt--30"
                  key={index}
                >
                  <div className="im_portfolio">
                    <div className="thumbnail_inner">
                      <div className="thumbnail">
                        <a
                          href={value.website}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {value.image}
                        </a>

                        {/* <Link
                          to={`/portfolio/${sanitize(value.title)}`}
                          onClick={() => setCurProject(value)}
                        >
                          {value.image}
                        </Link> */}
                      </div>
                    </div>
                    <div className="content">
                      <div className="inner">
                        <div className="portfolio_heading">
                          <div className="category_list">
                            <a
                              href={value.website}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {value.category}
                            </a>
                            {/* <Link
                              to={`/portfolio/${sanitize(value.title)}`}
                              onClick={() => setCurProject(value)}
                            >
                              {value.category}
                            </Link> */}
                          </div>
                          <h4 className="title">
                            <a
                              href={value.website}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {value.title}
                            </a>
                            {/* <Link
                              to={`/portfolio/${sanitize(value.title)}`}
                              onClick={() => setCurProject(value)}
                            >
                              {value.title}
                            </Link> */}
                          </h4>
                        </div>
                        <div className="portfolio_hover">
                          <p>{value.description}</p>
                        </div>
                      </div>
                    </div>
                    <a
                      className="transparent_link"
                      href={value.website}
                      target="_blank"
                      rel="noopener noreferrer"
                    >{" "}</a>
                    {/* <Link
                      className="transparent_link"
                      to={`/portfolio/${sanitize(value.title)}`}
                      onClick={() => setCurProject(value)}
                    ></Link> */}
                  </div>
                </div>
              ))}
            </div>

            {/* <div className="row">
              <div className="col-lg-12">
                <div className="view-more-btn mt--60 text-center">
                  <a className="rn-button-style--2 btn-solid v-m-p" href="/portfolio">
                    <span>View All Projects</span>
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Portfolio;
