import React, { Component } from "react";
import { FiHeart } from "react-icons/fi";

// import { Link } from "react-router-dom";


class About extends Component {
  render() {
    let title = "Who We Are.",
      description =
        "We are a team of industry professionals with years of experience spanning multiple diverse industries. We work together using a well-structured approach to support digital product ideas from inception through to real-world usage.",
      description2 =
        "We have experience in User Interface and Experience Design, Software Development (Web and Mobile), Business Development, and Digital Marketing processes.",
      description3 =
        "We are here to ensure your business reaches its full potential by saving you time, resources and enabling you scale.";

    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center abt-sect">
              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <div className="icon">
                      <FiHeart />
                    </div>
                    <h2 className="title">{title}</h2>
                    <p className="description">{description}</p>
                    <p className="description">{description2}</p>
                    <p className="description">{description3}</p>
                    {/* <div className="purchase-btn">
                      <Link className="btn-transparent" to="/about">
                        LEARN MORE ABOUT US
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/about/human-oriented.jpg"
                    alt="About Images"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default About;
