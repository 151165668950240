import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FaTwitter, FaLinkedinIn } from "react-icons/fa";
const logoUrl = (
  <img src="/assets/images/logo/aq-logo.png" alt="Acquira Labs Logo" />
);

const SocialShare = [
  // {Social: <FaFacebookF /> , link: 'https://www.facebook.com/'},
  {
    Social: <FaLinkedinIn />,
    link: "https://www.linkedin.com/company/acquiralabs",
  },
  // {Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
  { Social: <FaTwitter />, link: "https://twitter.com/acquiralabs" },
];

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer-area footer-style-01 bg_color--6">
          {/* Start Call to Action Area  */}
          <div className="im-call-to-action-area ptb--70 im-separator">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-8 col-xl-6 col-md-12 col-sm-12 col-12">
                  <div className="inner">
                    <h2 className="text-white mb--0">
                      Enough Talk, Let's Build Something Together
                    </h2>
                  </div>
                </div>

                {/* <div className="col-lg-4 col-xl-4 offset-xl-2 col-md-12 col-sm-12 col-12">
                  <div className="call-to-cation-tbn text-left text-lg-center mt_md--20 mt_sm--20">
                    <a
                      className="btn-default btn-large btn-border btn-opacity"
                      href="mailto:hello@acquiralabs.com"
                    >
                      Send us a message
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          {/* End Call to Action Area  */}

          {/* Start Footer Area  */}
          <div className="footer-wrapper ptb--70">
            <div className="container">
              <div className="row mobile-footer justify-content-between">
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div className="ft-text">
                    <div className="logo">
                      <Link to="/">{logoUrl}</Link>
                    </div>
                    <p>
                      Copyright &copy;{new Date().getFullYear()}.{" "}
                      <Link to="/">Acquira Labs</Link>
                    </p>
                  </div>
                </div>

                {/* Start Single Widget  */}
                {/* <div className="col-lg-2 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
                  <div className="footer-link">
                    <h4>Company</h4>
                    <ul className="ft-link">
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      <li>
                        <Link to="/team">Our Team</Link>
                      </li>
                      <li>
                        <Link to="/careers">Careers</Link>
                      </li>
                    </ul>
                  </div>
                </div> */}
                {/* End Single Widget  */}

                {/* Start Single Widget  */}
                <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt_md--40 mt_sm--40">
                  <div className="footer-link">
                    <h4>Say Hello</h4>
                    <ul className="ft-link">
                      {/* <li>
                        Email:{" "}
                        <a href="mailto:hello@acquiralabs.com">
                          hello@acquiralabs.com
                        </a>
                      </li> */}
                      <li>
                        Phone:{" "}
                        <a href="tel:+2348059986649">+234(0)8059986649</a>
                      </li>
                    </ul>

                    <div className="social-share-inner mt--20">
                      <ul className="social-share social-style--2 d-flex justify-content-start liststyle">
                        {SocialShare.map((val, i) => (
                          <li key={i}>
                            <a
                              href={`${val.link}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {val.Social}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* End Single Widget  */}
              </div>
            </div>
          </div>
          {/* End Footer Area  */}
        </footer>
      </React.Fragment>
    );
  }
}
export default Footer;
