import React, { Component } from "react";

class BrandTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <ul className="brand-style-2">
          <li>
            <img
              src="/assets/images/brand/a-home-to-adore-logo.png"
              alt="A home to adore"
            />
          </li>
          <li>
            <img
              src="/assets/images/brand/sunshine-reveal-logo.png"
              alt="Sunshine reveal"
            />
          </li>
        </ul>
      </React.Fragment>
    );
  }
}
export default BrandTwo;
